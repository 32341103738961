@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: Monda, sans-serif;
    -webkit-font-smoothing: antialiased;
}

html {
    scroll-behavior: smooth;
}
